import type { AuthFetch } from '~/types/AuthFetch'

/**
 * Provider to log out of the Auth service using email and password.
 *
 * @param authFetch
 * @returns The api call function.
 */
export function provideAuthApiLogout(authFetch: AuthFetch) {
  return async function () {
    try {
      await authFetch.$post<void>('/api/logout/')
    } catch (_) {}
  }
}
